import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth-routing.module').then(m => m.AuthRoutingModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/superAdmin-routing.module').then(m => m.AdminRoutingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'd-admin',
    loadChildren: () => import('./delegate-admin/delegate-admin-routing.module').then(m => m.DelegateAdminRoutingModule),
  },
  // {
  //   path: 'client',
  //   loadChildren: () => import('./client/client-routing.module').then(m => m.ClientRoutingModule),
  // },
  {
    path: 'coach',
    loadChildren: () => import('./coach/coach-routing.module').then(m => m.CoachRoutingModule),
  },
  {
    path: 'i-coach',
    loadChildren: () => import('./internal-coach/internal-coach-routing.module').then(m => m.InternalCoachRoutingModule),
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
