import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  notification = new BehaviorSubject({})
  profileUpdate = new BehaviorSubject({})
  profileEvent = this.profileUpdate.asObservable()
  notifyEvent = this.notification.asObservable()
  constructor(private api:ApiService) { }

  saveNotifications(data:any) {
    this.notification.next(data);
  }

  updateProfileDetails(data:any) {
    this.profileUpdate.next(data);
  }

}
