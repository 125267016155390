import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  permissions: any = {};

  user: any = false;
  token = ''
  private tokenKey = 'token';

  constructor(private api: ApiService, private router: Router) {
    this.token = localStorage.getItem(this.tokenKey) || ''
  }

  getAuthorizationToken(): string {
    this.token = localStorage.getItem(this.tokenKey) || ''
    return this.token
  }

  setAuthorizationToken(token: string) {
    localStorage.setItem(this.tokenKey, token);
    this.token = token;
  }



  async me() {
    const user = localStorage.getItem('user')
    localStorage.removeItem('page')

    return;
  }
  async load() {
    const data: any = localStorage.getItem('user')
    if (data) {
      this.user = JSON.parse(data)
      if (!this.user) {
        await this.router.navigate(['/auth/login'])
      }
    } else {
      // await this.router.navigate(['/auth/login'])
    }

  }
  async login(credentials: any) {
    const { data, message }: any = await this.api.post('adminSignIn', credentials);
    const { authToken, email, role, userName, userId, url, adminUserId } = data
    this.setAuthorizationToken(authToken);
    this.user = { email, role, userName, userId, url, adminUserId };
    localStorage.setItem('user', JSON.stringify(this.user))
    if (data?.modules?.length > 0) {
      data?.modules.forEach((element: any) => {
        this.permissions[element.moduleName] = element.visible;
      });
      localStorage.setItem('permissions', JSON.stringify(this.permissions))
    }
    return { data, message }

  }

  async forgotPassword(credentials: any) {
    const res: any = await this.api.post('forgetPassword', credentials);
    return res;
  }

  async resetPassword(credentials: any) {
    await this.api.post('resetPassword', credentials);
  }

  async logout() {
    await this.api.post('logoutUser?authToken=' + this.token + '&email=' + this.user.email, {});
    this.setAuthorizationToken('');
    localStorage.removeItem('user')
    localStorage.removeItem('page')

    this.user = null;
    await this.router.navigate(['/auth/login'])
  }
  private storageKey = 'rememberedUser';

  rememberUser(username: string, password: string) {
    const credentials = { username, password };
    localStorage.setItem(this.storageKey, JSON.stringify(credentials));
  }

  getRememberedUser() {
    const credentials = localStorage.getItem(this.storageKey);
    return credentials ? JSON.parse(credentials) : null;
  }

  clearRememberedUser() {
    localStorage.removeItem(this.storageKey);
  }
}
