import { CommonModule } from '@angular/common';
import {Component, OnInit} from '@angular/core';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import {FieldType} from '@ngx-formly/material';

@Component({
  selector: 'app-field-file',
  templateUrl: './field-file.component.html',
  styleUrls: ['./field-file.component.scss'],
  standalone:true,
  imports:[CommonModule, FormlyModule ]
})
export class FieldFileComponent extends FieldType<FieldTypeConfig>  implements OnInit {
  multiple: any = false;

  ngOnInit() {
    setTimeout(() => {
      this.multiple = this.to['multiple'];
    }, 100);
  }

  setValue(target: any) {
    if (target.files.length > 0) {
      this.formControl.setValue(target.files[0]);
    }
  }
}
