<div class="container">
    <div class="row">
        <div class="col-11">
            <h4 class="font textColor">Question</h4>
        </div>
        <div class="col-1">
            <button mat-icon-button class="buttonR" (click)="add()">
                <i class="bi bi-plus-circle "></i>
            </button>
        </div>
    </div>
    <div class="content" fxLayout="row wrap" fxLayout.sm="column" *ngIf="field.fieldGroup; else empty">
        <ng-container *ngFor="let f of field.fieldGroup; let i = index">
            <div class="row">
                <div class="col-11">
                    <formly-field [field]="f">

                    </formly-field>
                </div>
                <div class="col-1 mt-2">
                    <button mat-icon-button class="buttonR" (click)="remove(i)">
                        <i class="bi bi-dash-circle"></i>
                    </button>
                </div>
            </div>

        </ng-container>

    </div>
</div>


<br />
<div>
</div>
<ng-template #empty>
    <div style="display: flex; justify-content: center; width:500px">
        No {{ to.label | lowercase }} added
    </div>
</ng-template>