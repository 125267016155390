import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldArrayType, FormlyFormBuilder, FormlyModule } from '@ngx-formly/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {  MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-repeat',
  standalone: true,
  imports: [CommonModule , FormlyModule , ReactiveFormsModule , FormsModule, MatIconModule , MatFormFieldModule , MatInputModule] ,
  templateUrl: './repeat.component.html',
  styleUrls: ['./repeat.component.css']
})
export class RepeatComponent extends FieldArrayType {
 
}
