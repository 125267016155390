import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authService = localStorage.getItem('token')

  if (authService) {
    return true
  }
  return false;
};

