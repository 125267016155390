import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgSwitch, NgSwitchDefault, NgSwitchCase } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderComponent, NgHttpLoaderModule } from 'ng-http-loader';
import { ApiService } from './_services/api.service';
import { NotificationsService } from './_services/notifications.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [NgSwitch, NgSwitchDefault, NgSwitchCase, RouterOutlet , HttpClientModule , NgHttpLoaderModule ]
})
export class AppComponent {
  title = 'admin';
  
  constructor(private api:ApiService,
    private notificationService:NotificationsService) {}

  ngOnInit(): void {
    // this.getNotification()
  }

  // getNotification() {
  //   this.api.getRequest('getNotifications').subscribe((res: any) => {
  //     if (res) {
  //       this.notificationService.saveNotifications(res)
  //     }
  //   })
  // }
}
