import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from './app/_interceptor/auth.interceptor';
import { ResponseInterceptor } from './app/_interceptor/response.interceptor';
import { AuthService } from './app/_services/auth.service';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { PhoneComponent } from './app/_components/phone/phone.component';
import { FieldFileComponent } from './app/_components/field-file/field-file.component';
import { RepeatComponent } from './app/_components/repeat/repeat.component';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormControl, ValidationErrors } from '@angular/forms';
import { FieldValidatorFn } from '@ngx-formly/core/lib/models';
import { TextEditorComponent } from './app/_components/text-editor/text-editor.component';
export function RequiredValidatorMessage(err: any, field: FormlyFieldConfig) {
  return `${field.templateOptions?.label || 'This field'} is required`;
}

export function EmailValidator(control: FormControl | any): ValidationErrors | null {
  return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    .test(control.value) ? null : { 'email': true };
}

export function phoneNumber(control: FormControl | any): ValidationErrors | null {
  return /^\d{6,15}$/.test(control.value) ? null : { 'phone': true };
}

export function minLengthValidationMessage(error: any, field: FormlyFieldConfig) {
  return `Should have atleast ${field?.props?.minLength} characters`;
}

export function maxLengthValidationMessage(error: any, field: FormlyFieldConfig) {
  return `This value should be less than ${field?.props?.maxLength} characters`;
}

bootstrapApplication(AppComponent, {
  providers: [importProvidersFrom(BrowserModule, BrowserAnimationsModule, HttpClientModule, MatNativeDateModule, FormlyMatDatepickerModule, ToastrModule.forRoot(
    {
      timeOut: 3000,
      preventDuplicates: true,
      enableHtml: true,
      closeButton: true,
      progressBar: true
    }
  ), AppRoutingModule, MatDialogModule, FormlySelectModule, FormlyMaterialModule,
    FormlyModule.forRoot({
      validationMessages: [
        { name: 'required', message: RequiredValidatorMessage },
        { name: 'email', message: "Invalid Email" },
        { name: 'validatePhoneNumber', message: "Invalid Phone Number" },
        { name: 'minLength', message: minLengthValidationMessage },
        { name: 'maxLength', message: maxLengthValidationMessage },

      ],
      validators: [
        { name: 'email', validation: EmailValidator },
        { name: 'phone', validation: phoneNumber },

      ],

      types: [
        { name: 'file', component: FieldFileComponent, wrappers: ['form-field'] },
        { name: 'repeat', component: RepeatComponent, wrappers: ['form-field'] },
        { name: 'phone', component: PhoneComponent, wrappers: ['form-field'] },
        { name: 'text', component: TextEditorComponent, wrappers: ['form-field'] },



      ],
    }),
    NgHttpLoaderModule.forRoot()
  ), { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
  {
    provide: APP_INITIALIZER,
    useFactory: (auth: AuthService) => () => auth.load(),
    deps: [AuthService],
    multi: true
  }
    , provideAnimations(), provideAnimations()]
})
  .catch(err => console.error(err));
